import { createObservableDataAction, IAction } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetAllItemMeasurementUnitMeasureInput } from '../DataActionExtension.g';
import { IK3ItemMeasurementUnitMeasure } from '../DataServiceEntities.g';

export default createObservableDataAction({
    action: <IAction<IK3ItemMeasurementUnitMeasure[]>>retailAction,
    input: inputData => {
        return createGetAllItemMeasurementUnitMeasureInput({ Paging: { Top: 250 } });
    }
});
