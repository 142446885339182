import { createObservableDataAction, IAction, ICreateActionContext } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetAllPhaseTableInput } from '../DataActionExtension.g';
import { IK3PhaseTable } from '../DataServiceEntities.g';

export default createObservableDataAction({
    action: <IAction<IK3PhaseTable[]>>retailAction,
    input: (context: ICreateActionContext) => {
        return createGetAllPhaseTableInput({ Paging: { Top: 250 } });
    }
});
