import { getSelectedProductIdFromActionInput } from '@msdyn365-commerce-modules/retail-actions';
import { createObservableDataAction, IAction } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetItemMeasurementSizeDisplayOrderByProductInput } from '../DataActionExtension.g';
import { IK3ItemMeasurementSizeDisplayOrder } from '../DataServiceEntities.g';

export default createObservableDataAction({
    action: <IAction<IK3ItemMeasurementSizeDisplayOrder[]>>retailAction,
    input: inputData => {
        const productId = getSelectedProductIdFromActionInput(inputData);
        return createGetItemMeasurementSizeDisplayOrderByProductInput({ Paging: { Top: 250 } }, Number(productId));
    }
});
