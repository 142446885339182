import { createObservableDataAction, IAction } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetK3BrandTableByBrandInput } from '../DataActionExtension.g';
import { IK3BrandTable } from '../DataServiceEntities.g';

export default createObservableDataAction({
    action: <IAction<IK3BrandTable[]>>retailAction,
    input: inputData => {
        const brandId = inputData.data.productDetails.result?.BrandId;
        return createGetK3BrandTableByBrandInput({ Paging: { Top: 250 } }, brandId);
    }
});
