import { createObservableDataAction, IAction } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetK3InventTableByItemInput } from '../DataActionExtension.g';
import { IK3InventTable } from '../DataServiceEntities.g';

export default createObservableDataAction({
    action: <IAction<IK3InventTable[]>>retailAction,
    input: inputData => {
        const itemId = inputData.data.product.result?.ItemId;
        return createGetK3InventTableByItemInput({ Paging: { Top: 250 } }, itemId);
    }
});
