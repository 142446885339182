import { createObservableDataAction, IAction } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetAllItemMeasurementSizeDisplayOrderInput } from '../DataActionExtension.g';
import { IK3ItemMeasurementSizeDisplayOrder } from '../DataServiceEntities.g';

export default createObservableDataAction({
    action: <IAction<IK3ItemMeasurementSizeDisplayOrder[]>>retailAction,
    input: inputData => {
        return createGetAllItemMeasurementSizeDisplayOrderInput({ Paging: { Top: 250 } });
    }
});
