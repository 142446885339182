import { getSelectedProductIdFromActionInput } from '@msdyn365-commerce-modules/retail-actions';
import { createObservableDataAction, IAction } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy';
import { createGetItemMeasurementTableByProductInput } from '../DataActionExtension.g';
import { IK3ItemMeasurementTable } from '../DataServiceEntities.g';

export default createObservableDataAction({
    action: <IAction<IK3ItemMeasurementTable[]>>retailAction,
    input: inputData => {
        const productId = getSelectedProductIdFromActionInput(inputData);
        return createGetItemMeasurementTableByProductInput({ Paging: { Top: 250 } }, Number(productId));
    }
});
